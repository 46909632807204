.event-block {
  padding: 15px;
}
.event-block h2 {
  font-family: Poppins, Arial, Helvetica, sans-serif;
  font-size: 1.3em;
  background-color: lightgrey;
  padding: 10px;
}
.event-block h3 {
  font-size: 1em;
}
.event-block h3 svg {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -0.125em;
  margin-right: 5px;
}
.event-block p {
  font-size: 0.95em;
}
.event-images {
  display: flex;
  flex-direction: row;
}
.event-images img {
  max-height: 250px;
  margin: 15px;
}

.event-videos {
    display: flex;
    flex-direction: column;
  }
.event-videos div {
    margin-bottom: 15px;
  }
.event-sounds {
    display: flex;
    flex-direction: column;
  }
.event-sounds div {
    margin-bottom: 15px;
  }

@media screen and (max-width: 640px) {
  .event-images {
    display: flex;
    flex-direction: column;
  }
  .event-images img {
    margin: 15px;
  }
}
