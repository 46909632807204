body {
  margin: 0;
  background-color: #fcfcfc;
}
main {
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  hyphens: auto;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100%;
}
#page-content {
  padding: 5px 10px;
  margin: 50px 0px;
  background-color: #fcfcfc;
}

.app-header {
  height: 50px;
  width: 100%;
  flex-grow: 1;
  margin: 0px;
  color: #fcfcfc;
  background-color: rgb(24, 103, 128);
  position: fixed;
  top: 0px;
  z-index: 1100;
}
.app-filters {
  min-height: 50px;
  padding: 15px;
  margin-top: 25px;
}
.app-filters svg {
  width: 2em;
  height: 2em;
  display: inline-block;
  vertical-align: -0.6em;
  margin-right: 5px;
}
.filters-select {
  z-index: 1099;
  margin: 5px 0;
}
.app-map {
  flex-grow: 1;
}
.event-details {
  width: 100%;
  background-color: rgb(235, 223, 198);
  padding: 5px;
}
.event-details h2 {
  font-size: 1em;
}
.app-footer {
  line-height: 25px;
  width: 100%;
  margin: 0px;
  padding: 10px;
  background-color: rgb(24, 103, 128);
  color: #fcfcfc;
  text-align: center;
}
.app-footer p {
  padding: 0;
  margin: 0;
  font-size: 0.9em;
}
.app-footer a {
  color: #94b9e4;
}
.footer-align-right {

}
.financeurs {
  text-align: center;
}
.img-financeur {
  max-height: 75px;
}
h1 {
  font-size: 1.7em;
  text-align: center;
  margin: 0;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  border-bottom: 1px solid #0076ff;
}
h1 > a,
h1 > a:visited,
h1 > a:hover {
  color: #fcfcfc;
  text-decoration: none;
  border-bottom: none;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: "\`";
}

@media screen and (max-width: 640px) {

  #map-container {
    margin: 0 15px;
  }
}
